<template>
  <div class="box">
    <!-- 头部部分 -->
    <div class="title">
      <div class="left">
        <div class="logo" @click="logoToIndex">
          <img v-bind:src="centerConfig.logoUrl" />
        </div>
        <!-- <span>心理健康咨询中心</span> -->
      </div>
      <div class="right">
        <img src="../assets/home/mindPhone-icon.png" alt="" />
        <span class="consulting">{{ centerConfig.hotline }}</span>
        <!-- <span class="login">登录</span> -->
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="nav">
      <ul>
        <router-link to="/index"> <li>首页</li> </router-link>
        <router-link :to="'/' + item.url" v-for="item in cateList" :key="item.cateId">
          <li>
            {{ item.cateName }}
          </li>
        </router-link>
      </ul>
    </div>
    <div>
      <router-view />
    </div>
    <!-- foot部分 -->
    <div class="foot">
      <div class="foot-content">
        <div class="foot-left">
          <!-- <div class="school">{{ centerConfig.centerName }}</div> -->
          <div class="address">
            <div>公司地址：</div>
            <div>{{ centerConfig.centerAddr }}</div>
          </div>
          <div class="phone">电话：{{ centerConfig.contcatPhone }}</div>
          <div class="email">邮箱：{{ centerConfig.contcatMail }}</div>
        </div>
        <!-- <div class="foot-center">
          <div class="foot-center-title">友情链接</div>
          <div class="foot-centerInfo">
            <div class="foot-center-list" style="padding-right: 10px" v-for="item in firendLinks" :key="item.id" @click="friendLink(item.linkUrl)">
              {{ item.linkName }}
            </div>
          </div>
        </div> -->
        <div class="foot-center">
          <div class="foot-center-title">友情链接</div>
          <div class="foot-centerInfo">
            <div class="foot-center-list" v-for="item in firendLinks" :key="item.id" @click="friendLink(item.linkUrl)">
              <div class="hotLink">{{ item.linkName }}</div>
            </div>
          </div>
        </div>

        <div class="foot-right">
          <div class="circle-box" @click="elDialog">
            <div class="circle">
              <img v-bind:src="centerConfig.wxmpQrcode" />
            </div>
            <div class="circle-text">心灵通小程序</div>
          </div>
          <div class="square-box" @click="elDialog2">
            <div class="square">
              <img v-bind:src="centerConfig.wxQrcode" />
            </div>
            <div class="square-text">天津渤海公众号</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="foot-publicist">Copyright 2021- {{ year }} {{ centerConfig.centerName }} . All rights reserved</div>
        <!-- <div class="foot-publicist">Copyright 2021- {{ year }} 北京心灵通科技有限公司 . All rights reserved</div>
        <div class="foot-publicist"><a href="https://beian.miit.gov.cn" target="_blank">京ICP备2021006657号-6</a></div>
        <div class="foot-publicist" style="display: none"><a href="http://www.beian.gov.cn">京公网安备 11010802035955</a></div> -->
      </div>
    </div>
    <!-- 二维码弹框 -->
    <el-dialog :visible.sync="visible1" width="400px">
      <img :src="centerConfig.wxmpQrcode" width="100%" />
    </el-dialog>
    <el-dialog :visible.sync="visible2" width="400px">
      <img :src="centerConfig.wxQrcode" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { setItem } from '../utils/storage'
export default {
  name: 'HelloWorld',
  data() {
    return {
      visible2: false,
      visible1: false,
      year: 0,
      cateList: [],
      centerConfig: {},
      firendLinks: [],
      routerList: {
        1: '/notice',
        2: '/paper',
        3: '/central',
        4: '/activityCenter',
        5: '/mind',
        6: '/course',
        7: '/fun',
        8: '/activityCenter',
      },
    }
  },
  methods: {
    elDialog() {
      this.visible1 = true
    },
    elDialog2() {
      this.visible2 = true
    },
    // 数据请求
    getCateList() {
      get('/index/common')
        .then((res) => {
          this.cateList = res.data.cateList
          this.centerConfig = res.data.centerConfig
          setItem('centerConfig', res.data.centerConfig)
          this.firendLinks = res.data.firendLinks
        })
        .catch((err) => {
          // console.log(err);
        })
    },
    friendLink(e) {
      window.open(e, '_blank') //在新窗口打开
    },
    logoToIndex() {
      this.$router.push({ path: '/index' })
    },
  },
  mounted() {
    this.getCateList()
    this.year = new Date().getFullYear()
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.active-text {
  color: blue;
}
.box {
  background: #ffffff;
}
.title {
  width: 1080px;
  height: 104px;
  border-bottom: 1px solid #e5eaf1;
  margin: 0 auto;
  position: relative;
}

.logo > img {
  height: 56px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.title > .right {
  height: 104px;
  line-height: 104px;
  float: right;
  height: 104px;
}
.login {
  padding-left: 64px;
  color: #4676f8;
  width: 28px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4676f8;
  line-height: 22px;
}
.consulting {
  width: 316px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
}
.right > img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  vertical-align: middle;
}
.nav > ul {
  width: 1080px;
  height: 61px;
  border-bottom: 0px solid #e5eaf1;
  margin: 0 auto;
  list-style: none;
  line-height: 61px;
  display: flex;
}
ul > li {
  display: inline-block;
  margin-right: 50px;
}
ul > a {
  margin-right: 56px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
}
ul > a:hover {
  color: #4676f8;
  border-bottom: 2px solid #4676f8;
  border-radius: 2px;
}
a {
  text-decoration: none;
  display: block;
}

.router-link-active li {
  color: #4676f8;
  border-radius: 2px;
}
// 组件轮播样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  height: 100%;
  line-height: 50%;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.banner {
  width: 100%;
  background: rgba(3, 12, 37, 0.16);
  position: relative;
}

.el-carousel {
  height: 640px !important;
}
.center {
  width: 1098px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.center > .left {
  width: 682px;
}
.center > .right {
  width: 342px;
  height: 100%;
  margin-left: 72px;
}
.inside-box {
  margin: 0px 16px;
}
.newsTitle {
  display: flex;
  justify-content: space-between;
}
.news {
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  // float: left;
}
img {
  vertical-align: middle;
}
.seeMore {
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
}
.newsH2 {
  display: flex;
  height: 28px;
  margin-top: 32px;
}
.newsH3 {
  display: flex;
  height: 28px;
}
.news-icon {
  width: 40px;
  height: 22px;
}
h2 {
  font-weight: 600;
  color: #020a1b;
  font-size: 20px;
}
h3 {
  width: 598px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p {
  font-size: 14px;
  color: #9b9da2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 48px;
}
.news-notice {
  width: 650px;
  height: 88px;
  padding-top: 12px;
  font-size: 14px;
  color: #9b9da2;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-notice p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  white-space: initial;
}

.el-tag {
  width: 40px;
  height: 22px;
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  padding: 0 7px;
  line-height: 20px;
  font-size: 12px;
  color: #409eff;
  border-width: 0px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
  border: 1px solid;
}
.borderBottom {
  border-bottom: 1px solid #e5eaf1;
  margin-top: 24px;
  margin-bottom: 24px;
}
.right-title {
  display: flex;
  justify-content: space-between;
}
// 标题统一样式
.headline {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 25px;
}
.newActivities {
  width: 342px;
  height: 192px;
  background-color: pink;
  margin-top: 24px;
  border-radius: 6px;
}
.newActivities-text {
  width: 309px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 16px auto 0px;
}
.btn-list {
  width: 342px;
  height: 144px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.btn-list-info {
  margin: 0px;
  width: 159px;
  height: 48px;
  border-radius: 6px;
  margin-top: 24px;
  border: none;
  font-size: 16px;
}
.one {
  color: white;
  background-color: #fca274;
}
.two {
  color: white;
  background-color: #74bdfc;
}
.three {
  color: white;
  background-color: #7894fb;
}
.four {
  color: white;
  background-color: #38f2bc;
}
// 去除elm组件自带样式
.el-button + .el-button {
  margin-left: 0px;
}
.img-roster {
  width: 100%;
  height: 121px;
  margin-top: 48px;
}
.laughably-public {
  margin-top: 48px;
}
.laughably-info {
  margin-top: 32px;
}
.laughably-list {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.course-list {
  width: 195px;
  height: 173px;
  float: left;
  margin-left: 20px;
  margin-top: 32px;
}
.course-list-img {
  width: 195px;
  height: 110px;
  border-radius: 6px;
}
h4 {
  margin-top: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hot {
  font-size: 12px;
  color: #9b9da2;
}
.mindSmallBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mind-img {
  width: 159px;
}

.mind-img-list {
  width: 159px;
  height: 159px;
  margin-bottom: 12px;
  border-radius: 6px;
  margin-top: 24px;
}
.mine-text-list {
  width: 159px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
}
.mineBigImgBox {
  margin-top: 24px;
  width: 324px;
  height: 228px;
}
.bigImg {
  width: 341px;
  height: 192px;
  border-radius: 6px;
  margin-bottom: 12px;
}
.bigImg-text {
  width: 324px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.case {
  margin-top: 32px;
}
.case-list {
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 16px;
}
.case-list-active {
  color: #4676f8;
}

.mind-box {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
}
.mindArticle {
  margin-top: 32px;
}
.mind-article-list {
  display: flex;
}
.mind-article-more {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  line-height: 20px;
}
.mind-article-list-img {
  width: 220px;
  height: 124px;
  border-radius: 6px;
}
.mind-article-list-text {
  margin-left: 24px;
  width: 406px;
  height: 124px;
}
.mind-article-title {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mind-article-info {
  margin-top: 12px;
  width: 406px;
  font-size: 14px;
  color: #9b9da2;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: initial;
}
.mindArticleBorder-bottom {
  width: 100%;
  height: 1px;
  background: #e5eaf1;
  margin: 16px 0;
}
.teacherTeam-box {
  margin-top: 32px;
  display: flex;
}
.teacherTeam-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.teacherName {
  width: 45px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
}
.teacherIntro {
  width: 26px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6f7174;
  line-height: 20px;
  padding-left: 12px;
}
.teacherTeam-text {
  margin-left: 24px;
}
.teacherTeam-text-info {
  width: 506px;
  height: 60px;
  font-size: 12px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 20px;
  margin-top: 12px;
}

// foot样式
.foot {
  background: #62656d;
  // display: flex;
  // height: 176.8px;
  overflow: hidden;
}
.foot-left {
  padding-top: 32px;
  padding-bottom: 32px;
  width: 340px;
  height: 100%;
}
.foot-center {
  margin-left: 88px;
  margin-top: 32px;
  width: 370px;
  align-items: center;
}
.foot-publicist {
  font-size: 14px;
  padding-top: 5px;
  text-align: center;
  color: #eee;
  cursor: pointer;
}
.foot-publicist:nth-child(2n) {
  margin-bottom: 8px;
}
.foot-publicist:last-child {
  padding-bottom: 10px;
}
.foot-publicist > a {
  color: #eee;
}
.school {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 12px;
}
.address {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  margin-left: 27px;
}
.address div:nth-child(1) {
  white-space: nowrap;
}

.phone {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
  margin-left: 55px;
}
.email {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 55px;
}
// 1111111111111
.foot-center-title {
  // width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
}
.foot-centerInfo {
  width: 450px;
  max-height: 100px;
  // 多行文本溢出剪裁
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}
.foot-center-list {
  //   width: 200px;
  //   width: 100%;
  max-width: 100%;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: default !important;

  display: inline-block;

  //   padding-right: 20px;
  padding-right: 200px;
}
.hotLink {
  cursor: pointer;
  display: flex;
}
.foot-center-list-info {
  padding-right: 32px;
}

// .foot-center-title {
//   // width: 56px;
//   height: 20px;
//   font-size: 14px;
//   font-weight: 600;
//   color: #ffffff;
//   line-height: 20px;
// }
// .foot-centerInfo {
//   // width: 450px;
//   max-height: 100px;
//   // 多行文本溢出剪裁
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 3;
// }
// .foot-center-list {
//   width: 200px;
//   //   max-width: 200px;
//   border: 1px solid plum;

//   margin-top: 12px;
//   font-size: 14px;
//   font-weight: 400;
//   color: #ffffff;
//   cursor: pointer;
//   display: inline-block;
// }
// .foot-center-list-info {
//   padding-right: 32px;
// }
.foot-right {
  width: 291px;
  height: 109px;
  margin-left: 175px;
  display: flex;
  margin-top: 32px;
}
.circle-box,
.square-box {
  cursor: pointer;
}
.circle > img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 14px;
}
.circle-text {
  margin-right: 61px;
  margin-bottom: 12px;
  //   width: 80px;
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.square-text {
  width: 154px;
  height: 14px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.square-box {
  width: 154px;
  margin-left: 25px;
}
.square > img {
  text-align: center;
  width: 82px;
  height: 82px;
  background-color: white;
  margin-bottom: 14px;
}
.router-link-active {
  border-bottom: 2px solid #4676f8;
}

.foot-content {
  margin: 0 auto;
  width: 1264px;
  display: flex;
}
</style>
