<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/mind/img-top.png" alt="" />
    </div>
    <div class="mind-box">
      <div class="mind">
        <div class="mind-nav">
          <div :class="{ 'mind-nav-all': true, tabActive: tabActive == 0 }" @click="tabChange(0)"><span>全部</span></div>
          <div :class="{ 'mind-nav-list': true, tabActive: tabActive == PsyhelpNavItem.cateId }" v-for="PsyhelpNavItem in PsyhelpNav" :key="PsyhelpNavItem.cateId" @click="tabChange(PsyhelpNavItem.cateId)">{{ PsyhelpNavItem.cateName }}</div>
        </div>
        <div class="learnMind">
          <div class="learnMind-list hoverLearnMind hover-item hover-item-bigImg" v-for="PsyhelpListItem in PsyhelpList" :key="PsyhelpListItem.id" @click="toClasses(PsyhelpListItem.playUrl)">
            <div class="learnMind-list-info">
              <div class="learnMind-list-info-img">
                <img v-bind:src="PsyhelpListItem.imgUrl" />
              </div>
              <div class="learnMind-list-info-title hover-item-title">{{ PsyhelpListItem.subject }}</div>
              <!-- <div class="learnMind-list-info-hot">
              <img src="../assets/home/leftFour-icon.png" alt="">
              <span  class="learnMind-list-info-hot">{{PsyhelpListItem.courseView }}</span> 
            </div> -->
            </div>
          </div>
          <div class="no-content" v-if="!PsyhelpList || !PsyhelpList.length">
            <img src="../assets/home/noContent.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination :page-size="12" layout="prev, pager, next" :total="total" @current-change="currentChange" :current-page="currentPage"> </el-pagination>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
export default {
  name: 'HelloWorld',
  data() {
    return {
      PsyhelpNav: [],
      PsyhelpList: [],
      tabActive: 0,
      currentPage: 1, // 当前页码
      total: null,
    }
  },
  methods: {
    // 分页
    currentChange(val) {
      // console.log("切换后的页码", val);
      this.currentPage = val
      this.getPsyhelList()
    },
    async getPsyhelpNav() {
      get('/article/mediacates')
        .then((res) => {
          this.PsyhelpNav = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getPsyhelList(cateId = 0) {
      get('/article/medialist', { cateId: cateId, page: this.currentPage })
        .then((res) => {
          this.PsyhelpList = res.data.artList
          this.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    tabChange(cateId) {
      this.getPsyhelList(cateId)
      this.tabActive = cateId
    },
    tabFilter(list) {
      if (this.tabActive == 0) return list
      return list.filter((item) => {
        return item.cateId == this.tabActive
      })
    },
    toClasses(e) {
      //  window.location.href = e
      window.open(e, '_blank')
    },
  },
  mounted() {
    this.getPsyhelpNav()
    this.getPsyhelList()
    let siteConfig = getItem('centerConfig')
    document.title = '心理自助 - ' + siteConfig.centerName
  },
  updated() {
    window.scroll(0, 0)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// 主体部分
.no-content {
  margin: 0 auto;
}
.topImg > img {
  width: 100%;
  display: block; //解决图片下1像素bug
}
.mind-box {
  background: #eff2fa;
}
.mind-nav {
  padding-top: 40px;
  padding-bottom: 56px;
  display: flex;
}
.mind {
  width: 1080px;
  margin: 0 auto;
}
.mind-nav-all {
  width: 64px;
  height: 22px;
  cursor: pointer;
}
.mind-nav-all > span {
  font-size: 16px;
  font-weight: 400;
  // color: #4676F8;
  line-height: 16px;
  padding-left: 16px;
}
.mind-nav-list {
  // padding: 0 20px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  text-align: center;
  margin: 0px 12px;
  cursor: pointer;
}
.mind-nav-list:hover {
  color: #4676f8;
}
.tabActive {
  color: #4676f8;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #4676f8;
  line-height: 22px;
}
.learnMind {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 144px;
}
.learnMind-list {
  width: 338px;
  height: 273px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;
  position: relative;
  cursor: pointer;
}
.learnMind-list:nth-child(3n) {
  margin-right: 0;
}

.hoverLearnMind:hover {
  width: 338px;
  height: 273px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.hover-item:hover .hover-item-title {
  color: #4676f8;
}
.hover-item-bigImg:hover {
  transform: scale(1.02);
}

.learnMind-list-info {
  width: 306px;
  height: 240px;
}

.learnMind-list-info-img > img {
  width: 306px;
  height: 172px;
  background: #d8d8d8;
  border-radius: 6px;
}
.learnMind-list-info-title {
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  padding-top: 16px;
  // 多行文本溢出显示省略号
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // 单行文本溢出显示省略号
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.learnMind-list-info-hot {
  padding-top: 12px;
  font-size: 12px;
  color: #9b9da2;
}
.learnMind-list-info-hot > img {
  width: 16px;
  height: 18px;
  padding-right: 3px;
  vertical-align: middle;
}
// 分页样式
.el-pagination {
  padding-left: 60%;
  padding-bottom: 20px;
}
/deep/.el-pagination button:disabled {
  background-color: #eff2fa !important;
}
// /deep/.btn-next {
//   background-color: #eff2fa !important;
// }
// /deep/.btn-next {
//   color: #c0c4cc !important ;
// }

/deep/.el-pager li.number {
  background-color: #eff2fa !important;
}
// /deep/.number active{
//   background-color: #EFF2FA !important;
// }
</style>
